import * as Yup from 'yup';

export default function getMaximumDecimalSchema(maxDecimalPlaces: number = 2) {
  return Yup.number().test(
    'maxDecimals',
    `Field is limited to ${maxDecimalPlaces} decimal places.`,
    (value) => {
      if(value === undefined || value === null) return true;
      return Number.isInteger(value * 10**maxDecimalPlaces);
    }
  );
}
